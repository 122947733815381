
import loadable from '@loadable/component';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import './index.css';
import './i18n';
import "react-multi-carousel/lib/styles.css";


//custom imports below
const App = loadable(() => import('./App'));

//removed for one signal need to check it's effect.
// import * as serviceWorker from "./serviceWorker";

i18n.on('initialized', () => {
  ReactDOM.hydrate(<App />, document.getElementById('root'));
});

// ReactDOM.render(<App />, document.getElementById("root"));
// serviceWorker.unregister();

